<template>
  <div>
    <div class="wrapper">
      <!-- section -->
      <section class="section section-blue jpadding jpadding-20">
        <div v-if="mode == 'LOADING'" class="jcard jcard-main jcard-nohover">
          <div class="text-center loading-container">
            <i class="text-blue fad fa-spinner-third fa-spin fa-3x"></i>
          </div>
        </div>
        <div v-else-if="mode == 'ERROR'" class="jcard jcard-main jcard-nohover">
          <div class="jcard-content text-center">
            <h5 class="mb-3">
              <i class="fad fa-exclamation-circle text-blue fa-3x"></i><br />
            </h5>
            <p>
              We are unable to fetch your subscription from the Talkii servers
              at the moment. Please make sure you are connected to the internet
              and try again later.
            </p>
            <!-- <div class="jcard-btn-container jcard-btn-container-center">
              <router-link :to="{ name: 'Plans' }" class="jbtn">
                <i class="fa fa-sync"></i
              ></router-link>
            </div> -->
          </div>
        </div>
        <!-- jcard -->
        <div v-else class="jcard jcard-main jcard-nohover">
          <!-- <h4 class="mb-4 mt-3">Subscription</h4> -->
          <div class="card-title">
            <h3>Subscription</h3>
          </div>
          <div class="row">
            <div class="col-md-6">
              <label
                ><strong><i class="fa fa-file"></i> Product Name</strong></label
              >
              <p>Talkii</p>
            </div>
            <div class="col-md-6">
              <label
                ><strong
                  ><i class="fa fa-truck-loading"></i> Type/Distribution</strong
                ></label
              >
              <p>{{ typeList[user.aacType] }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <label
                ><strong
                  ><i class="fa fa-calendar"></i> Start Date</strong
                ></label
              >
              <p>{{ subscription.start_time }}</p>
            </div>
            <div class="col-md-6">
              <label
                ><strong
                  ><i class="fa fa-calendar"></i> Next Billing Time</strong
                ></label
              >
              <p>{{ subscription.next_billing_time }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <label
                ><strong
                  ><i class="fa fa-calendar"></i> Billed (Cycle)</strong
                ></label
              >
              <p>month(s)</p>
            </div>
            <div class="col-md-6">
              <label
                ><strong
                  ><i class="fa fa-exclamation-triangle"></i> Status</strong
                ></label
              >
              <p>
                <span class="badge badge-success"
                  >{{ subscription.status }}
                </span>
              </p>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <label
                ><strong
                  ><i class="fa fa-file"></i> Talkii status</strong
                ></label
              >
              <p>
                <span class="badge badge-secondary">{{
                  statusList[user.status]
                }}</span>
              </p>
            </div>
            <div class="col-md-6"></div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <label
                ><strong
                  ><i class="fa fa-file"></i> Subscrption Reference</strong
                ></label
              >
              <p>
                <span class="badge badge-secondary">{{
                  subscription.agreementId
                }}</span>
              </p>
            </div>
            <div class="col-md-6">
              <label
                ><strong><i class="fa fa-box"></i> Hardware</strong></label
              >
              <p><span class="badge badge-danger">No</span></p>
            </div>
          </div>

          <hr />

          <div v-if="user.aacType == 1">
            <h4 class="mb-4 mt-3">Delivery</h4>
            <p>
              <i class="fa fa-truck"></i> Talkii Package delivered succefully on
              <strong>25.01.2021</strong>.
              <i class="fa fa-check text-success"></i>
            </p>
            <hr />
          </div>
          <h3 class="mb-4 mt-3">Invoice</h3>
          <p>
            <i class="fa fa-file-pdf"></i> Talkii Package 1 Year - 25.01.2021
          </p>

          <hr />
          <a class="jbtn jbtn-red mt-3" href=""
            ><i class="fa fa-times"></i> Unsubscribe</a
          >
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/app";
import db from "@/firebase/firebaseInit";
// import axios from "axios";
//import C from "@/constants";
import P from "@/plans";

export default {
  name: "Subscription",
  data() {
    return {
      statusList: P.STATUS_INDEX,
      typeList: P.TYPE_INDEX,

      hasLoaded: false,
      displayBuy: false, // hide normal view and show buy message
      displayError: false, // hide normal view and show that something went wrong

      userId: "",
      //platformUser: null, // get platformUser from state store
      subscriptionId: "",
      //subscription: null, // get subscription from state store
    };
  },
  methods: {
    init() {
      //get talkii user id
      this.userId = firebase.auth().currentUser.uid;

      //load and check talkii user status

      //if valid status get platform user
      this.getPlatformUser(this.userId);

      //has_active_sub is false, load buy

      //if valid platform user, get subscription
      if (this.platformUser != null && this.platformUser != "") {
        //if agreementId is there
        this.getSubscription(this.platformUser.agreementId);
      }
    },
    buyPlan() {
      this.$store.commit("startAddContrat");
    },
    // getPlatformUser() {
    //   axios
    //     .post("/api/functions.php", {
    //       request: "get_platform_user",
    //     })
    //     .then(function(response) {
    //       this.platformUser = response.data;
    //     })
    //     .catch(function(error) {
    //       console.log(error);
    //     });
    // },
    // getSubscription() {
    //   axios
    //     .post("/api/functions.php", {
    //       request: "get_subscription",
    //     })
    //     .then(function(response) {
    //       this.subscription = response.data;
    //     })
    //     .catch(function(error) {
    //       console.log(error);
    //     });
    // },
  },
  computed: {
    platformUser: {
      get() {
        return this.$store.getters.getPlatformUser;
      },
    },
    subscription: {
      get() {
        return this.$store.getters.getSubscription;
      },
    },
    user: {
      get() {
        return this.$store.getters.getUser;
      },
    },
    mode: {
      get() {
        return this.$store.getters.getMode;
      },
    },
  },
  created() {
    console.log("created");
    //get user id from firebase auth
    this.userId = firebase.auth().currentUser.uid;

    //get user document from firestore
    var docRef = db.collection("users").doc(this.userId);
    docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          console.log("firebase GET userDoc SUCCESS");
          // save userDoc in store
          this.$store.commit("setUser", doc.data());
        } else {
          // doc.data() will be undefined in this case
          console.log("UserDoc not found!");
        }
      })
      .catch((error) => {
        console.log("firebase GET userDoc ERROR: ", error);
      });

    //load platform user from db into store
    this.$store.dispatch("getPlatformUserAndSubscription", this.userId);
    //load active subscription from db into store
    //this.$store.dispatch("getSubscription", this.platformUser.agreementId);
  },
  mounted() {
    //this.userId = firebase.auth().currentUser.uid;
    console.log("mounted");
  },
};
</script>

<style scoped></style>
